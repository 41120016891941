import { Box, Button, Chip, Divider, FormLabel, IconButton, Modal, Paper, Stack, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from "react-hook-form";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CommentIcon from '@mui/icons-material/Comment';
import Fab from '@mui/material/Fab';
import { useTranslation } from 'react-i18next'
import Title from '../shared/Title';
import { styled } from '@mui/material/styles';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormTextField, FormAutocomplete, FormDatePicker, FormSwitchs, FormSelect } from '../shared/form-components';
import { useQuery, useMutation } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import {
    FIND_APPOINTMENT_BY_ID,
    FIND_SERVICE_BY_ID,
    GET_APPOINTMENTS_BY_PATIENT_ID, 
    GET_AVAILABILITIES, 
    GET_DISCOUNTS, 
    GET_INSURANCES, 
    GET_MEDICAL_TESTS, 
    GET_PAGINATED_APPOINTMENTS, 
    GET_QUEUED_APPOINTMENTS as GET_QUEUED_APPOINTMENTS, 
    SEARCH_PATIENTS
} from '../graphql/queries'
import { CREATE_APPOINTMENT, UPDATE_APPOINTMENT } from '../graphql/mutations'
import Confirmation from '../shared/Confirmation';
import { 
    getPatientText, 
    getAppointmentText, 
    getServiceText
} from '../shared/label-helpers';
import MedicalTestChips from '../medical-test/MedicalTestChips';
import * as moment from 'moment';
import CancelAppointmentButton from './CancelAppointmentButton';
import { HasAccessTo, useAccessConstraints } from '../auth/hooks';
import { useSnackbar } from 'notistack';
import { formatMoney } from '../shared/format-helpers';
import { green, red } from '@mui/material/colors';
import StatusChip from '../shared/StatusChip';
import ModalContainer from '../shared/ModalContainer';
import PatientForm from '../patient/PatientForm';
import AppointmentComments from '../appointment/AppointmentComments';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AppointmentVoucher from './AppointmentVoucher';
import { useTenantId } from '../tenant/hooks';
import TagSelect from '../tag/TagSelect';
import { FormAutocompleteGetServicesQuery } from '../service/form-components';

const PatientModal = ({open, onClose, onAdded, selectedPatient }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <ModalContainer sx={{padding: 2}} width={450}>
                <PatientForm onCancel={onClose} onAdded={onAdded} selectedPatient={selectedPatient} />
            </ModalContainer>
        </Modal>
    )
}

const CommentsModal = ({open, onClose, comments, appointmentId }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <ModalContainer sx={{padding: 0}}>
                <AppointmentComments 
                    appointmentId={appointmentId}
                    comments={comments}/>
            </ModalContainer>
        </Modal>
    )
}


const  FormAutocompleteSearchPatientsQuery = graphql(SEARCH_PATIENTS, {
    props: ({ data: { searchPatients , refetch, loading }, ownProps: {tenantId} }) => ({
        options: searchPatients || [],
        getOptionLabel: getPatientText,
        loading: loading,
        onSearchTextChanged: (text) => refetch({searchText: text||'', tenants: [tenantId]})
    }),
    options: ({tenantId})=>({
        variables: {
            searchText: '',
            tenants: [tenantId]
        }
    })
})(FormAutocomplete);

const  FormAutocompleteGetAppointmentsByPatientIdQuery = graphql(GET_APPOINTMENTS_BY_PATIENT_ID, {
    props: ({ data: { getAppointments, loading}}) => ({
        options: getAppointments || [],
        loading: loading
    }),
    skip: ({patientId})=>!patientId,
    options: ({patientId, tenantId}) => ({
        // Options are computed from `props` here.
        variables: {
            patientId: patientId,
            tenants: [tenantId]
        }
    }),
})(({patientId, ...props})=><FormAutocomplete 
    getOptionLabel={getAppointmentText} 
    {...props} 
/>);

const getInsuranceLabel = (insurance) => {
    if (!insurance) {
        return "";
    }

    if ( typeof insurance === "string") {
        return insurance;
    }
    return `${insurance.name}`;
}

const  FormAutocompleteGetInsurancesQuery = graphql(GET_INSURANCES, {
    props: ({ data: { getInsurances , loading} }, lastProps) => ({
        options: getInsurances || [],
        loading: loading,
        getOptionLabel: getInsuranceLabel
    }),
    options: ({tenantId})=>({
        variables: {
            enabled: true,
            tenants: [tenantId]
        }
    })
})(FormAutocomplete);


const getDiscountLabel = (discount) => {
    if (!discount) {
        return "";
    }

    if ( typeof discount === "string") {
        return discount;
    }
    return `${discount.name} - (${discount.percent}%)`;
}

const  FormAutocompleteGetDiscountsQuery = graphql(GET_DISCOUNTS, {
    props: ({ data: { getDiscounts , options, loading } }) => ({
        options: getDiscounts || [],
        getOptionLabel: getDiscountLabel,
        loading: loading,
        getOptionValue: (val) => val,
        getKey: (val) => val?._id || 'id'
    }),
    options: ({tenantId})=>({
        variables: {
            enabled: true,
            tenants: [tenantId]
        }
    })
})(FormAutocomplete);


const getAvailabilityValue = (availability) => {
    if (!availability) {
        return "";
    }

    if ( typeof availability === "string") {
        return availability;
    }

    if (moment.isMoment(availability)) {
        return availability.format('LT');
    }
    return availability.time;
}

const combineAvailabilitiesOptions = (options, time) => {
    return (options || []).map(o=>{
        return {
            ...o,
            available: o.available || o.time === time
        }
    });
}

const  FormSelectAvailabilitiesQuery = graphql(GET_AVAILABILITIES, {
    props: ({ data: { getAvailabilities , refetch} }, lastProps) => ({
        options: (getAvailabilities || [])
    }),
    skip: ({date, specialistId, serviceId, loading}) => !(date && specialistId && serviceId) || loading,
    options: ({date, specialistId, serviceId}) => ({
        // Options are computed from `props` here.
        variables: {
            date: moment.utc(date).local().startOf('day').utc(),
            specialistId: specialistId,
            serviceId: serviceId
        },
        fetchPolicy: 'network-only'
    }), 
})(({specialistId, date, options, time, isAvailabilityDisabled, ...otherProps}) => 
    <FormSelect 
        options={combineAvailabilitiesOptions(options, time)} 
        getOptionLabel={getAvailabilityValue}
        getOptionValue={getAvailabilityValue}
        isOptionDisabled={isAvailabilityDisabled}
        {...otherProps} />);

const medicalTestSchema = yup.object().shape({
    _id: yup.string().required(),
    name: yup.string().required(),
    enabled: yup.bool().nullable(),
    metadata: yup.object().shape({
        color: yup.string()
    })
});

const tagSchema = yup.object().shape({
    _id: yup.string().required(),
    name: yup.string().required(),
    description: yup.string().nullable(),
});

const DataLine = styled(Box, {
})(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
}));

const DashedDivider = styled(Divider, {
})(({ }) => ({
    borderStyle: 'dashed'
}));

const UNEDITABLE_STATUSES = ['COMPLETE', 'CANCELLED', 'WORKING'];
const UNCANCELLED_STATUSES = ['COMPLETE', 'CANCELLED'];

export default ({
    appointmentId,
    time: pTime, date: pDate, specialistId: pSpecialistId, serviceId: pServiceId,
    onCancel, 
    onAdded 
}) => {
    const [isNew, setIsNew] = useState(!appointmentId);
    const [tenantId] = useTenantId();
    const [currentSelectedPatient, setCurrentSelectedPatient] = useState();
    const [service, setService] = useState();
    const [editable, setEditable] = useState(isNew);
    const [editableVisible, setEditableVisible] = useState(false);
    const [cancelVisible, setCancelVisible] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const specialistIds = useAccessConstraints({
        entity: 'Appointment',
        code: 'CREATE',
        constraint: 'Specialist'
    });

    const [ createAppointment ] = useMutation(isNew ? CREATE_APPOINTMENT : UPDATE_APPOINTMENT, {
        refetchQueries: [
            GET_AVAILABILITIES,
            GET_PAGINATED_APPOINTMENTS,
            GET_QUEUED_APPOINTMENTS,
            FIND_APPOINTMENT_BY_ID,
        ]
    })
    
    const {data: appointmentData, loading: appointmentLoading} = useQuery(FIND_APPOINTMENT_BY_ID, {
        variables: {
            appointmentId: appointmentId
        },
        skip: isNew || !appointmentId
    });

    const {data: serviceData, loading: serviceLoading} = useQuery(FIND_SERVICE_BY_ID, {
        variables: {
            serviceId: pServiceId
        },
        skip: !pServiceId
    });

    const schema = yup.object({
        _id: yup.string(),
        patientId: yup.string().required(t('RequiredField', {Field: t('Name')})),
        date: yup.date().typeError(t('InvalidDate')).required().min(moment().startOf("day").toDate(), t('DateInThePast')),
        time: yup.string().required(t('RequiredField', {Field: t('Time')})),
        serviceId: yup.string().required(t('RequiredField', {Field: t('Service')})),
        previousAppointmentRequired: yup.bool(),
        previousAppointmentId: yup.string().when('previousAppointmentRequired', {
            is: true,
            then: (s) => s.required()
        }),
        insuranceId: yup.string(),
        insuranceAmount: yup.number().nullable(true),
        requiredMedicalTests: yup.array().of(medicalTestSchema),
        doneMedicalTests: yup.array().of(medicalTestSchema),
        tags: yup.array().of(tagSchema).nullable(true),
        comment: yup.string(),
        discount: yup.mixed([yup.object({
            _id: yup.string(),
            name: yup.string(),
            enabled: yup.bool().nullable(),
            percent: yup.number(),
            code: yup.string()
        }), yup.string()]).nullable(true),
        code: yup.string().nullable(true)
    });

    const methods = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        values: {
            date: moment(pDate).startOf('day').utc(),
            time: pTime,
            serviceId: pServiceId
        },
        defaultValues: {
            requiredMedicalTests: [],
            doneMedicalTests: [],
            date: moment().startOf('day').utc()
        }
    });

    const { watch, handleSubmit, control, setValue } = methods;

    const [openPM, setOpenPM] = useState(false);
    const toggleOpenPM = () => {
        setOpenPM(!openPM);
    }

    const [openACM, setOpenACM] = useState(false);
    const toggleOpenACM = () => {
        setOpenACM(!openACM);
    }

    const handlePatientAdded = (patient) => {
        setCurrentSelectedPatient(patient);
        setOpenPM(false);
    }

    const {
        _id,
        patientId, 
        date, 
        previousAppointmentRequired, 
        time, 
        requiredMedicalTests,
        insuranceId,
        serviceId,
        discount,
        code,
        status
     } = watch();
    const [specialistId, setSpecialistId] = useState(pSpecialistId);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [openVoucher, setOpenVoucher] = useState(false);
    const [upserted, setUpserted] = useState(false);

    const [subTotalAmount, setSubTotalAmount] = useState(0);
    const [totalDiscountPercent, setTotalDiscountPercent] = useState(0);
    const [prevTime, setPrevTime] = useState(undefined);

    const processedSpecialistIds = (isNew && specialistIds.length==0 && specialistId)  ? [specialistId] : specialistIds.filter(s=> !isNew || !specialistId || s==specialistId);

    useEffect(()=>{
        if (!pServiceId) {
            return;
        }

        if (!serviceData) {
            return;
        }

        if (serviceData._id != serviceId) {
            return;
        }
        
        handleServiceValueChanged(serviceData);

    }, [pServiceId, serviceData, serviceId]);

    useEffect(()=>{
        if (!appointmentData?.findAppointment) {
            return;
        }
        
        setValue("_id", appointmentData.findAppointment._id);
        setValue("patientId", appointmentData.findAppointment.patient?._id);
        setValue("date", moment.utc(appointmentData.findAppointment.date).local().startOf('date'));
        setValue("time", appointmentData.findAppointment.time);
        setValue("serviceId", appointmentData.findAppointment.service?._id);
        setValue("previousAppointmentId", appointmentData.findAppointment.previousAppointment?._id);
        setValue("invoiceId", appointmentData.findAppointment.invoice?._id);
        setValue("insuranceId", appointmentData.findAppointment.insurance?._id);
        setValue("requiredMedicalTests", appointmentData.findAppointment.requiredMedicalTests || []);
        setValue("tags", appointmentData.findAppointment.tags || []);
        setValue("doneMedicalTests", appointmentData.findAppointment.doneMedicalTests || []);
        setValue("discount", appointmentData.findAppointment.discount);
        setValue("insuranceAmount", appointmentData.findAppointment.insuranceAmount);
        setValue('previousAppointmentRequired', !!(appointmentData.findAppointment.service?.previousAppointmentRequired));
        setValue('code', appointmentData.findAppointment.code);
        setValue('status', appointmentData.findAppointment.status);
        
        const editable = UNEDITABLE_STATUSES.indexOf(appointmentData.findAppointment.status)==-1;
        const cancellable = UNCANCELLED_STATUSES.indexOf(appointmentData.findAppointment.status)==-1;
        setEditableVisible(editable);
        setCancelVisible(cancellable);
        setService(appointmentData.findAppointment.service);
        setCurrentSelectedPatient(appointmentData.findAppointment.patient);
        setSubTotalAmount(appointmentData.findAppointment.totalAmount);
        setTotalDiscountPercent(((appointmentData.findAppointment.discount?.percent)??0)/100);
        setSpecialistId(appointmentData.findAppointment.specialist?._id);
        setPrevTime(appointmentData.findAppointment.time);
    }, [appointmentData]);

    const onSubmit = async data => {
        setOpenConfirmation(false);

        const { 
            code, status, doneMedicalTests, previousAppointmentRequired, date,
            ...otherProps
        } = data;

        const response = await createAppointment({
            variables: {
                input: {
                    date: moment(date).startOf('day').utc(),
                    ...otherProps,
                    doneMedicalTests: doneMedicalTests?.map(({required, ...otherProps})=>({
                        ...otherProps
                    })) || []
                },
                ...(isNew ? {tenants:[tenantId]}: {})
            }
        });

        enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
        if (response.data.createAppointment){
            setValue("_id", response.data.createAppointment._id);
        }
        setUpserted(true);
        setTimeout(()=>{
            setOpenVoucher(true);
        }, 0);
    }

    const { data } = useQuery(GET_MEDICAL_TESTS, {
        variables: {
            tenants: [tenantId]
        }
    });

    const handleServiceValueChanged = (service) => {
        setService(service);
        setValue('requiredMedicalTests',service?.requiredMedicalTests || []);
        setSpecialistId(service?.specialist?._id||'');
        setValue('previousAppointmentRequired', !!(service?.previousAppointmentRequired));
    }

    useEffect(() => {
        if (!service) {
            setSubTotalAmount(0);
        }

        if (insuranceId) {
            setSubTotalAmount(service?.amountWithInsurance ?? service?.amount ?? 0);
        } else {
            setSubTotalAmount(service?.amount ?? 0);
        }

    }, [service, insuranceId])

    const handleDiscountValueChanged = (discount) => {
        setTotalDiscountPercent((discount?.percent??0)/100);
    }

    const totalDiscount = Math.floor(subTotalAmount*totalDiscountPercent)
    const totalAmount = subTotalAmount - totalDiscount;

    let medicalTests = [
        ...(requiredMedicalTests || []).map(x => ({...x, required: true})),
        ...(data?.getMedicalTests||[]), 
    ];
    medicalTests = medicalTests.filter((mt,index) => index === medicalTests.findIndex(mtt=> mtt._id === mt._id));

    const handleClose = () => {
        setOpenConfirmation(false);
    }

    const showConfirmation = (_, e) => {
        setOpenConfirmation(true);
    }

    const switchEditable = () => {
        setEditable(!editable);
    }

    const switchIsNew = () => {
        setIsNew(!isNew);
        setEditable(true);
        setValue('code', undefined);
        setValue('status', undefined);
        setValue("_id", undefined);
        if (!isNew) {
            setPrevTime(undefined);
        }
    }

    const handleCloseVoucher = () => {
        setOpenVoucher(false);
        if (isNew) {
            onAdded && onAdded({
                _id: _id
            });
        } 
        if (upserted) {
            onCancel && onCancel();
        }
    }

    const handleOpenVoucher = () => {
        setOpenVoucher(true);
    }

    const isAvailabilityDisabled = (availability, value) => {
        if (!availability) {
            if (isNew) {
                return true;
            } else {
                const originalStartDateUtc = moment.utc(appointmentData.findAppointment.date).local().startOf('day').utc();
                const sameDate = originalStartDateUtc.isSame(date);
                const sameTime = appointmentData.findAppointment.time == time;
                return !(sameDate && sameTime);
            }
        }

        return !availability.available;
    }


    return (
        <>
            <AppointmentVoucher 
                open={openVoucher} 
                onClose={handleCloseVoucher} 
                appointmentId={_id} />
           
            <PatientModal 
                open={openPM} 
                onAdded={handlePatientAdded}
                onClose={toggleOpenPM} 
            />

            <CommentsModal 
                open={openACM} 
                appointmentId={appointmentId}
                comments={appointmentData?.findAppointment?.comments||[]}
                onClose={toggleOpenACM}             
            />

            <Confirmation 
                open={openConfirmation}
                onNo={handleClose}
                onClose={handleClose}
                onYes={handleSubmit(onSubmit)}
                title={t('Confirmation')}
                description={t('AreYouSureQuestion')}
            />
            <FormProvider {...methods}>
                <Paper>
                    <Box p={2}>
                        <Stack direction="row" justifyContent="space-between">
                            <Stack direction="row" spacing={1} alignItems="center">
                                {appointmentData?.findAppointment?.confirmed && <Chip color='success' size='small' label={t('Confirmed')} />}
                                <Title sx={{marginBottom: 0}}>{t(isNew ? "CreateAppointment" : (editable ? "EditAppointment" : "ViewAppointment"))}</Title>
                            </Stack>

                            {!isNew && (
                            <Stack direction="row">

                                {!editable && !isNew && cancelVisible && 
                                    <HasAccessTo entity="Appointment" code="CANCEL">
                                        <CancelAppointmentButton appointmentId={appointmentId} onCancel={onCancel}/>
                                    </HasAccessTo>
                                }

                                <Tooltip title={t('ViewInvoice')}>
                                    <IconButton size='small' onClick={handleOpenVoucher}>
                                        <ReceiptIcon />
                                    </IconButton>
                                </Tooltip>
                            
                                <Tooltip title={t('ViewComments')}>
                                    <IconButton size='small' onClick={toggleOpenACM}>
                                        <CommentIcon />
                                    </IconButton>
                                </Tooltip>
                            
                                <HasAccessTo entity="Appointment" code="CREATE">
                                    <Tooltip title={t('CopyAppointment')}>
                                        <IconButton size='small' onClick={switchIsNew}>
                                            <ContentCopyIcon />
                                        </IconButton>
                                    </Tooltip>
                                </HasAccessTo>

                                {editableVisible && 
                                    <HasAccessTo entity="Appointment" code="UPDATE">
                                        <Tooltip title={t(editable ? 'ViewMode' : 'EditMode')}>
                                            <IconButton color={editable ? 'success' : 'primary'} size='small' onClick={switchEditable}>
                                                { editable ? <VisibilityIcon />: <EditIcon /> }
                                            </IconButton>
                                        </Tooltip>
                                    </HasAccessTo>
                                }
                            </Stack>)
                            }
                        </Stack>
                    </Box>
                    <Divider />
                    {(code || status) && (
                    <>
                        <Box p={2}>
                            <Stack direction="row" justifyContent="space-between">
                                {status && <StatusChip status={status}/>}
                                {code && 
                                    (<Typography variant='caption'>
                                        {code}
                                    </Typography>)
                                }
                            </Stack>
                        </Box>
                        <Divider />
                    </>)}
                    <Box p={2}>
                        <Stack spacing={2}>
                            <Stack direction="row" spacing={2}>
                                <FormAutocompleteSearchPatientsQuery
                                    sx={{ flex: 'auto' }}
                                    control={control}
                                    selected={currentSelectedPatient}
                                    tenantId={tenantId}
                                    name="patientId"
                                    label={t('Patient')}
                                    disabled={!editable || !isNew}
                                />
                                {editable && isNew && (
                                    <HasAccessTo entity="Patient" code="CREATE">
                                        <Fab size='small' color='primary' variant="contained" onClick={toggleOpenPM}>
                                            <AddIcon />
                                        </Fab>
                                    </HasAccessTo>
                                    )}
                            </Stack>
                            <FormAutocompleteGetServicesQuery
                                control={control}
                                name="serviceId"
                                specialistIds={processedSpecialistIds}
                                onValueChanged={handleServiceValueChanged}
                                tenantId={tenantId}
                                label={t('Service')} 
                                disabled={!!pServiceId || !editable || !isNew} />

                            <Stack direction="row" spacing={2}>
                                <FormDatePicker
                                    disablePast
                                    label={t("Date")}
                                    control={control}
                                    name="date" 
                                    disabled={!editable}
                                />
                                {serviceId && 
                                    (<FormSelectAvailabilitiesQuery
                                        fullWidth
                                        label={t("Time")}
                                        control={control}
                                        name="time"
                                        specialistId={specialistId}
                                        serviceId={serviceId}
                                        disabled={!editable}
                                        time={!isNew?prevTime:undefined}
                                        date={date}
                                        loading={appointmentLoading}
                                        isAvailabilityDisabled={isAvailabilityDisabled}
                                        />
                                    )
                                }
                            </Stack>
                            {previousAppointmentRequired && 
                                (<FormAutocompleteGetAppointmentsByPatientIdQuery
                                    control={control}
                                    name="previousAppointmentId"
                                    patientId={patientId || ""}
                                    label={t('PreviousAppointment')}
                                    tenantId={tenantId}
                                    disabled={!editable || !isNew} />)
                            }
                            <FormAutocompleteGetInsurancesQuery
                                control={control}
                                name="insuranceId"
                                label={t('Insurance')}
                                tenantId={tenantId}
                                disabled={!editable} />
                            {insuranceId && (
                                <FormTextField 
                                    label={t('InsuranceAmount')}
                                    type="number"
                                    name="insuranceAmount"
                                    size='small'
                                    fullWidth
                                    disabled={!editable} 
                                />)
                            }
                            {editable && isNew && (<FormTextField 
                                fullWidth
                                rows={3}
                                multiline={true} 
                                label={t('Comment')}
                                control={control}
                                name="comment"
                                disabled={!editable} />)
                            }
                            <TagSelect 
                                label={t('Tags')}
                                control={control}
                                tenantId={tenantId}
                                name="tags"
                                categories={['APPOINTMENT']}
                                disabled={!editable}
                            />
                            <FormLabel>
                                {t("RequiredTests")}
                            </FormLabel>
                            <div>
                                <MedicalTestChips medicalTests={requiredMedicalTests}/>
                            </div>
                            <FormSwitchs 
                                options={medicalTests}
                                label={t('DoneMedicalTests')}
                                control={control}
                                noDataMessage={t('ThereIsNotData')}
                                getOptionValue={(opt)=>opt}
                                compareOptions={(left, right)=> (left?._id??left) === (right?._id??right) }
                                name="doneMedicalTests"
                                disabled={!editable || !isNew}
                            />
                        </Stack>
                    </Box>
                    <Divider />
                    <Box p={2}>
                        <Stack>
                            <DataLine>
                                <Typography>
                                    {t('ServiceAmount')}
                                </Typography>
                                <Typography color={green[500]}>
                                    {formatMoney(subTotalAmount)}
                                </Typography>
                            </DataLine>
                            <DataLine>
                                <FormAutocompleteGetDiscountsQuery 
                                    variant="standard"
                                    sx={{flex:'0.7'}}
                                    label={t("Discount")}
                                    control={control}
                                    onValueChanged={handleDiscountValueChanged}
                                    name="discount" 
                                    tenantId={tenantId}
                                    disabled={!editable} />
                                <Typography color={red[500]}>
                                    {discount ? formatMoney(totalDiscount) : '---'}
                                </Typography>
                            </DataLine>
                        </Stack>
                    </Box>
                    <DashedDivider />
                    <Box p={2}>
                        <Stack>
                            <DataLine>
                                <Typography>
                                    {t('Total')}:
                                </Typography>
                                <Typography fontWeight='bold' color={green[500]}>
                                    {formatMoney(totalAmount)}
                                </Typography>
                            </DataLine>
                        </Stack>
                    </Box>
                    <Divider />
                    <Box p={2} >
                        <Stack direction="row" justifyContent="space-between" spacing={2}>
                            <Button variant='outlined' color="error" onClick={onCancel}>{t('Back')}</Button>
                            {editable && <Button fullWidth variant='contained' color='primary' onClick={handleSubmit(showConfirmation)} >{t('Save')}</Button> }
                        </Stack>
                    </Box>
                </Paper>
            </FormProvider>
        </>
    )
}