import { useEffect, useState } from "react"
import SpecialtyButtons from "./SpecialtyButtons";
import SpecialistButtons from "./SpecialistButtons";
import { Link, useLocation } from "react-router-dom";
import { Box, Breadcrumbs, Button, Typography } from "@mui/material";
import DefaultContainer from "../shared/DefaultContainer";
import SpecialistServices from "./SpecialistServices";
import AppointmentCalendar from "../appointment/AppointmentCalendar";
import { useTranslation } from "react-i18next";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import {CalendarIcon} from "@mui/x-date-pickers";
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import Title from "../shared/Title";

export default () => {
    const {key} = useLocation();

    const {t} = useTranslation();

    const [specialty, setSpecialty] = useState();
    const [specialist, setSpecialist] = useState();
    const [service, setService] = useState();
    const [calendar, setCalendar] = useState(false);

    useEffect(()=>{
        setSpecialty(null);
        setSpecialist(null);
        setCalendar(false);
    }, [key]);

    const onSpecialtyClicked = (specialt) => {
        setSpecialty(specialt)
    }

    const onSpecialistClicked = (specialis) => {
        setSpecialist(specialis)
    }

    const clearAll = () => {
        setSpecialty(null);
        setSpecialist(null);
        setCalendar(false);
    }

    const clearSpecialist = () => {
        setSpecialist(null);
        setCalendar(false);
    }

    return <>
        <DefaultContainer plain>
            <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                <Link
                    data-testid="main-title"
                    onClick={clearAll}
                    color="inherit"
                    underline="hover"
                    style={{ color: 'inherit', textDecoration: 'inherit', display: 'flex', alignItems: 'center'}}
                >
                    <Title sx={{mb:0}}>{t('Dashboard')}</Title>
                </Link>
                {specialty && (
                    <Link
                        onClick={clearSpecialist}
                        color="inherit"
                        underline="hover"
                        style={{ color: 'inherit', textDecoration: 'inherit', display: 'flex', alignItems: 'center'}}
                    >
                        <LocalHospitalIcon color="primary" sx={{ mr: 0.5 }} />
                        {specialty.name}
                    </Link>
                )}
                {specialist && (
                    <Link
                        onClick={() => {
                            setCalendar(false)
                            setService(undefined)
                        }}
                        color="inherit"
                        underline="hover"
                        style={{ color: 'inherit', textDecoration: 'inherit', display: 'flex', alignItems: 'center'}}
                    >
                        <AssignmentIndIcon color="primary" sx={{ mr: 0.5 }} />
                        {specialist.name}
                    </Link>
                )}
                {service && (
                    <Link
                        onClick={() => {
                            setCalendar(false)
                            setService(undefined)
                        }}
                        color="inherit"
                        underline="hover"
                        style={{ color: 'inherit', textDecoration: 'inherit', display: 'flex', alignItems: 'center'}}
                    >
                        <MedicalServicesIcon color="primary" sx={{ mr: 0.5 }} />
                        {service.name}
                    </Link>
                )}
                {specialist && (
                    calendar ?
                    <Typography
                        sx={{ display: 'flex', alignItems: 'center' }}
                        color="text.primary"
                    >
                        <CalendarIcon color="primary" sx={{ mr: 0.5 }} />
                        {t('Calendar')}</Typography>
                    : <Typography
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="text.primary"
                        >
                            <MedicalServicesIcon color="primary" sx={{ mr: 0.5 }} />
                            {t('Services')}</Typography>
                )}
                
            </Breadcrumbs>
            {!specialty && <SpecialtyButtons onSpecialtyClick={onSpecialtyClicked}></SpecialtyButtons>}
            {specialty && !specialist && <SpecialistButtons onSpecialistClick={onSpecialistClicked} specialtyId={specialty?._id} onBack={clearAll}></SpecialistButtons>}
            {!calendar && specialist && 
                <Box mt={2}>
                    <SpecialistServices onCalendarClick={(service)=> {
                        setService(service)
                        setCalendar(true)
                    }} specialist={specialist} onBack={clearSpecialist}></SpecialistServices>
                </Box>}
            { calendar &&
                <>
                    <Box mt={2}>
                        <AppointmentCalendar specialists={[specialist]} service={service} />
                        <Box mt={2}>
                            <Button variant="outlined" color="error" onClick={()=>setCalendar(false)} fullWidth>
                                {t('Back')}
                            </Button>
                        </Box>
                    </Box>
                </>
            }
        </DefaultContainer>
    </>
}