import { Box, Button, Paper, Stack } from "@mui/material"
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { GET_SERVICES } from "../graphql/queries";
import { formatMoney } from "../shared/format-helpers";
import { DataGrid } from "@mui/x-data-grid";
import { useTenantId } from "../tenant/hooks";

const buildColumns = (t, onCalendarClick) => ([
    { field: '_id', headerName: t('ID'), width: 60, hide: true },
    { field: 'name', headerName: t('Name'), width: 250 },
    { field: 'amount', headerName: t('Amount'), width: 250, valueGetter: ({value}) => formatMoney(value)},
    { field: 'amountWithInsurance', headerName: t('AmountWithInsurance'), width: 250, valueGetter: ({value}) => formatMoney(value)},
    { 
        filterable: false,
        sortable: false,
        field: 'serviceUrl', 
        headerName: t('Appointments'), 
        width: 170,
        renderCell: ({row}) => {
        return <Button 
            variant='contained' 
            color='success' 
            size="small" 
            onClick={()=>onCalendarClick(row)}>
            {t('Calendar')}
        </Button>
        }
    }
]);

export default ({specialist, onCalendarClick, onBack}) => {
    const { t } = useTranslation();
    const columns = buildColumns(t, onCalendarClick);
    const [tenantId] = useTenantId();

    const {data, loading} = useQuery(GET_SERVICES, {
        variables: {
            specialistIds: [specialist._id],
            tenants: [tenantId],
            enabled: true
        }
    });

    const rows = data?.getServices??[];

    return (
        <>
            <Paper>
                <Box sx={{ height: 450, width: '100%' }}>
                    <DataGrid
                        density='compact'
                        rows={rows}
                        columns={columns}
                        rowCount={rows.length??0}
                        loading={loading}
                        getRowId={(r)=>r._id}
                        disableRowSelectionOnClick
                        hideFooter={true}
                    />
                </Box>
            </Paper>

            <Box mt={2}>
                <Stack direction="row" spacing={2}>
                    <Button variant="outlined" color="error" onClick={onBack} fullWidth>
                        {t('Back')}
                    </Button>
                    <Button variant="contained" color="success" onClick={()=>onCalendarClick(undefined)} fullWidth>
                        {t('Calendar')}
                    </Button>
                </Stack>
            </Box>
        </>
    )
}