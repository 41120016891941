import moment from 'moment';
import { 
    Timeline, 
    TimelineItem,
    TimelineSeparator, 
    TimelineDot,
    TimelineConnector,
    TimelineContent,
    TimelineOppositeContent,
    timelineOppositeContentClasses,
    LoadingButton
} from '@mui/lab';
import { 
    Box, 
    Typography, 
    Card, 
    CardContent, 
    Stack, 
    Modal, 
    Divider,
    Paper,
    Button,
    LinearProgress,
    Tooltip,
    Fade,
 } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import BlockIcon from '@mui/icons-material/Block';
import Fab from '@mui/material/Fab';
import AppointmentForm from "./AppointmentForm";
import { useState } from "react";
import ModalContainer from '../shared/ModalContainer';
import { useMutation, useQuery } from "@apollo/client";
import { GET_AVAILABILITIES, FIND_APPOINTMENT_BY_ID, GET_QUEUED_APPOINTMENTS } from "../graphql/queries";
import { SET_APPOINTMENT_CONFIRMATION } from "../graphql/mutations";
import { useTranslation } from "react-i18next";
import Confirmation from '../shared/Confirmation';
import { HasAccessTo } from '../auth/hooks';
import StatusChip from '../shared/StatusChip';
import ForestIcon from '@mui/icons-material/Forest'
import { NavLink } from 'react-router-dom';

const DATE_TIME_FORMAT = 'yyyy-MM-DD HH:mm';

const AppointmentDetail = ({appointment }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [openAF, setOpenAF] = useState(false);
    const [setAppointmentConfirmation, {loading}] = useMutation(SET_APPOINTMENT_CONFIRMATION, {
        refetchQueries: [GET_AVAILABILITIES, FIND_APPOINTMENT_BY_ID, GET_QUEUED_APPOINTMENTS]
    });

    const handleConfirm = (confirm) => {
        setOpen(true);
        setIsConfirm(confirm);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleSubmit = ()=> {
        setAppointmentConfirmation({
            variables: {    
                appointmentId: appointment._id,
                confirm: isConfirm
            }
        });
        handleClose();
    }

    const switchOpen = () => {
        setOpenAF(!openAF);
    }

    const bgColor = appointment.confirmed===true? "success.light" :( appointment.confirmed===false? "error.light" : "warning.light");
    const textValueColor = appointment.confirmed===true? "text.secondary" :( appointment.confirmed===false? "text.primary" : "text.secondary");

    return (
        <>
          <AppointmentModal
                onClose={switchOpen}
                onAdded={switchOpen}
                appointmentId={appointment?._id}
                open={openAF}/>
           <Confirmation 
                open={open}
                onNo={handleClose}
                onClose={handleClose}
                onYes={handleSubmit}
                title={t('Confirmation')}
                description={t('AreYouSureQuestion')}
            />
            <Card sx={{ bgcolor: bgColor }}>
                <CardContent sx={{padding:0}}>
                    <Box p={1}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                            <StatusChip status={appointment.status} />
                            <Typography variant="h6" component="div">
                                {appointment?.code}
                            </Typography>
                        </Stack>
                    </Box>
                    <Divider></Divider>
                    <Box p={1}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                            <Typography fontWeight="bold">
                                {t('Service')}
                            </Typography>
                            <Typography sx={{ fontSize: 14 }} color={textValueColor}>
                                {appointment?.service?.name}
                            </Typography>
                        </Stack>
                    </Box>
                    <Divider></Divider>
                    <Box p={1}>
                        <Stack>
                            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                <Typography fontWeight="bold">
                                    {t('Birthday')}
                                </Typography>
                                <Typography sx={{ fontSize: 14 }} color={textValueColor}>
                                    {moment(appointment?.patient?.patientBirthday).format("YYYY-MM-DD")} ({moment(appointment?.patient?.patientBirthday).fromNow()})
                                </Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography fontWeight="bold">
                                    {t('PatientName')}
                                </Typography>
                                <Typography sx={{ fontSize: 14 }} color={textValueColor}>
                                    {appointment?.patient?.patientName}
                                </Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography fontWeight="bold">
                                    {t('Tutor')}
                                </Typography>
                                <Typography sx={{ fontSize: 14 }} color={textValueColor}>
                                    {appointment?.patient?.tutorFirstName} {appointment?.patient?.tutorLastName} 
                                </Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography fontWeight="bold">
                                    {t('TutorIdentification')}
                                </Typography>
                                <Typography sx={{ fontSize: 14 }} color={textValueColor}>
                                    {appointment?.patient?.tutorIdentification}
                                </Typography>
                            </Stack>

                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography fontWeight="bold">
                                    {t('Contact')}
                                </Typography>
                                <Typography sx={{ fontSize: 14 }} color={textValueColor}>
                                    {appointment?.patient?.tutorPhoneNumber}
                                </Typography>
                            </Stack>
                            
                        </Stack>
                        <Stack spacing={2}>
                            <Stack direction={'row'} spacing={2}>
                                {appointment?.status !== "COMPLETE" && appointment?.status !== "CANCELED" && (
                                <HasAccessTo entity="Appointment" code="CONFIRM" constraint='Specialist' entityRef={appointment?.specialist?._id}>
                                    <>
                                        {appointment.confirmed!==false &&
                                            (<LoadingButton 
                                                fullWidth
                                                loading={loading}
                                                variant="contained"
                                                size='small'
                                                onClick={() => handleConfirm(false) }
                                                color={"warning"}>
                                                        {t('Unconfirm')}
                                            </LoadingButton>)
                                        }
                                        {appointment.confirmed!==true &&
                                            (<LoadingButton 
                                                fullWidth
                                                loading={loading}
                                                variant="contained"
                                                size='small'
                                                onClick={() => handleConfirm(true) }
                                                color={"success"}>
                                                        {t('Confirm')}
                                            </LoadingButton>)
                                        }
                                        
                                    </>
                                </HasAccessTo>)
                                }

                            </Stack>
                            <Button 
                                color='primary'
                                size='small'
                                onClick={switchOpen}
                                variant='contained'>
                                {t('WatchDetails')}
                            </Button>
                        </Stack>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
}

const BlockedSection = () => {
    return <BlockIcon />
}

const AppointmentModal = ({open, onClose, time, date, selectedPatient, specialistId, onAdded, appointmentId, serviceId}) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            closeAfterTransition
        >
            <Fade in={open}>
                <ModalContainer sx={{padding: 0}}>
                    <AppointmentForm 
                        appointmentId={appointmentId}
                        onCancel={onClose}
                        time={time}
                        date={date}
                        selectedPatient={selectedPatient}
                        specialistId={specialistId}
                        serviceId={serviceId}
                        onAdded={onAdded}/>
                </ModalContainer>
            </Fade>
        </Modal>
    )
}

export default ({date, specialist, service}) => {
    const {t} = useTranslation();

    const { data, loading, refetch } = useQuery(GET_AVAILABILITIES, {
        variables:{
            date: moment(date).startOf('day').utc(),
            specialistId: specialist._id,
            serviceId: service?._id
        },
        fetchPolicy: 'network-only',
    });

    const [openAM, setOpenAM] = useState(false);
    const [time, setTime] = useState('');

    const [selectedPatient] = useState();

    const toggleOpenAM = (time) => () => {
        setOpenAM(!openAM);
        setTime(time)
    }

    const handleAppointmentAdded = () => {
        refetch();
        setOpenAM(false);
    }

    const count = data?.getAvailabilities.reduce((t,c)=>t+c.appointments.length,0)??0;
    const total = Math.max(1, data?.getAvailabilities.reduce((t,c)=>t+c.count,0)??0);
    const percent = (count/total)*100;

    return (
        <>
            <AppointmentModal 
                open={openAM} 
                selectedPatient={selectedPatient}
                date={date}
                time={time}
                serviceId={service?._id}
                onClose={toggleOpenAM(time)}
                onAdded={handleAppointmentAdded} 
                specialistId={specialist?._id}
            />
            <Stack spacing={2}>
                <Stack direction={'row'}>
                    <Typography flexGrow={1} align="center" component="h2" variant="h6" color="primary" gutterBottom>
                        {specialist.name}
                    </Typography>
                    <HasAccessTo entity="Appointment">
                        <Button
                            color='primary'
                            size='small'
                            variant='outlined'
                            component={NavLink} to={`/appointments?specialistId=${specialist._id}&date=${moment(date).format('yyyy-MM-DD')}&status=PENDING&status=QUEUED&status=INCOMPLETE&status=COMPLETE&status=WORKING`}>
                            {t('GoToTable')}
                        </Button>
                    </HasAccessTo>
                </Stack>
                <Paper>
                    {data?.getAvailabilities?.length>0 && (
                        <>
                            <Box p={2}>
                                <Typography variant='h5' align='center'>
                                    {count} / {total}  {t('Patients')}
                                </Typography>
                            </Box>
                            <LinearProgress variant='determinate' value={percent} />
                        </>
                    )}
                    <Box p={2}>
                        <Timeline 
                            sx={{
                                [`& .${timelineOppositeContentClasses.root}`]: {
                                flex: 0.2,
                                },
                            }}
                        >
                            { data && data.getAvailabilities.length==0 && 
                                <Typography>{t('ThereIsNotPatient')}</Typography>
                            }
                            {data && data.getAvailabilities.map(block=>{
                                return (
                                    <TimelineItem key={block.time}>
                                        <TimelineOppositeContent
                                            color="textsecondary"
                                            sx={{
                                                paddingTop: '16px'
                                            }}
                                        >
                                            {block.time}
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineDot variant='outlined'>
                                                {block.appointments.length}/{block.count}
                                            </TimelineDot>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Stack direction="row" spacing={2}>
                                                {block.appointments.map(a=>{
                                                    return <AppointmentDetail 
                                                            appointment={a}
                                                            key={a._id}
                                                            />
                                                })}
                                                <HasAccessTo
                                                    entity="Appointment" 
                                                    code="CREATE"
                                                    constraint="Specialist" 
                                                    entityRef={specialist._id}>
                                                    <Box sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center"
                                                    }}>
                                                    {
                                                        (!block.available)
                                                        ? <BlockedSection />
                                                        : (
                                                            <Fab 
                                                                size="small" 
                                                                color="primary" 
                                                                variant="contained"
                                                                onClick={toggleOpenAM(block.time)}>
                                                                <AddIcon />
                                                            </Fab>
                                                        )
                                                    }
                                                    </Box>
                                                </HasAccessTo>
                                                {block.timeOffs.map(to => (
                                                    <Tooltip title={
                                                        `${moment(to.dateFrom).format(DATE_TIME_FORMAT)} - ${moment(to.dateTo).format(DATE_TIME_FORMAT)}: ${to.reason}`
                                                    }>
                                                        <ForestIcon />
                                                    </Tooltip>
                                                ))}
                                            </Stack>
                                        </TimelineContent>
                                    </TimelineItem>
                                );
                            })}
                        </Timeline>
                    </Box>
                </Paper>
            </Stack>
        </>
    )
}