import { Box, Button, Divider, Paper, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from "react-hook-form";
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next'
import Title from '../shared/Title';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@apollo/client';
import { FIND_SERVICE_BY_ID, GET_MEDICAL_TESTS, GET_SERVICES, GET_SPECIALISTS } from '../graphql/queries';
import { CREATE_SERVICE, UPDATE_SERVICE } from '../graphql/mutations';
import { FormSwitch, FormTextField, FormAutocomplete, FormSelectMultiple, FormSlider, FormRadios } from '../shared/form-components';
import { getSpecialistText } from '../shared/label-helpers';
import { graphql } from '@apollo/client/react/hoc';
import { useSnackbar } from 'notistack';
import { useTenantId } from '../tenant/hooks';

const FormAutocompleteGetSpecialist = graphql(GET_SPECIALISTS, {
    props: ({ data: { getSpecialists , loading}}) => ({
        options: getSpecialists || [],
        loading: loading
    }),
    skip : ({loading}) => loading,
    options: ({tenantId})=>({
        variables: {
            enabled: true,
            tenants: [tenantId]
        }
    })
})(({...props})=><FormAutocomplete 
    getOptionLabel={getSpecialistText} 
    {...props} 
/>);

const FormAutocompleteGetMedicalTests = graphql(GET_MEDICAL_TESTS, {
    props: ({ data: { getMedicalTests , loading}}) => ({
        options: getMedicalTests || [],
        loading: loading
    }),
    skip : ({loading}) => loading,
    options: ({tenantId}) => ({
        variables: {
            enabled: true,
            tenants: [tenantId]
        }
    })
})(({...props})=><FormSelectMultiple 
    getOptionLabel={ (option) => `${option.name}`} 
    {...props} 
/>);

const medicalTestSchema = yup.object().shape({
    _id: yup.string().required(),
    name: yup.string().required(),
    enabled: yup.bool().nullable(),
    metadata: yup.object().shape({
        color: yup.string()
    })
});

const commissionModes = [
    {
        key: 'NONE',
        value: 'NONE',
        label: 'Specialist',
    },
    {
        key: 'PERCENT',
        value: 'PERCENT',
        label: 'Percent',
    },
    {
        key: 'AMOUNT',
        value: 'AMOUNT',
        label: 'Amount',
    }
]

const commissionPercentMarks = [
    {
        value: 0,
        label: '0%'
    },
    {
        value: 20,
        label: '20%'
    },
    {
        value: 30,
        label: '30%'
    },
    {
        value: 100,
        label: '100%'
    },
];

export default ({serviceId, onCancel, onAdded}) => {
    const isNew = !serviceId;

    const [tenantId] = useTenantId();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const schema = yup.object({
        _id: yup.string(),
        name: yup.string().required(t('RequiredField', {Field: t('Name')})),
        amount: yup.number().required(t('RequiredField', {Field: t('AmountWithoutInsurance')})),
        amountWithInsurance: yup.number().required(t('RequiredField', {Field: t('AmountWithInsurance')})),
        specialistId: yup.string().required(t('RequiredField', {Field: t('Specialist')})),
        requiredMedicalTests: yup.array().of(medicalTestSchema).required(),
        enabled: yup.bool().required(),
        commissionMode: yup.string(),
        commissionPercent: yup.number().when('commissionMode', {
            is: (cm) => cm === 'PERCENT',
            then: (s) =>  s.required(),
            otherwise: (s) => s.nullable(true)
        }),
        commissionAmount: yup.number().when('commissionMode', {
            is: (cm) => cm === 'AMOUNT',
            then: (s) =>  s.required(t('RequiredField', {Field: t('CommissionAmount')})),
            otherwise: (s) => s.nullable(true)
        }),

        commissionModeWithInsurance: yup.string(),
        commissionPercentWithInsurance: yup.number().when('commissionModeWithInsurance', {
            is: (cm) => cm === 'PERCENT',
            then: (s) =>  s.required(),
            otherwise: (s) => s.nullable(true)
        }),
        commissionAmountWithInsurance: yup.number().when('commissionModeWithInsurance', {
            is: (cm) => cm === 'AMOUNT',
            then: (s) =>  s.required(t('RequiredField', {Field: t('CommissionAmountWithInsurance')})),
            otherwise: (s) => s.nullable(true)
        }),

        previousAppointmentRequired: yup.bool().nullable(true)
    });

    const [ createService] = useMutation(isNew ? CREATE_SERVICE : UPDATE_SERVICE,{
        refetchQueries: [
            GET_SERVICES,
            FIND_SERVICE_BY_ID
        ]
    })
 
    const {data, loading} = useQuery(FIND_SERVICE_BY_ID, {
        variables: {
            serviceId: serviceId
        },
        skip: isNew
    });
    
    const methods = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        defaultValues: {
            _id: serviceId,
            enabled: true,
            requiredMedicalTests: [],
            commissionMode: 'NONE',
            commissionModeWithInsurance: 'NONE',
            previousAppointmentRequired: false
        }
    });
    const { watch, setValue, handleSubmit, formState: { isSubmitting } } = methods;

    const onSubmit = async data => {
        const response = await createService({
            variables: {
                input: data,
                ...(isNew ? {tenants:[tenantId]}: {})
            }
        });
        onAdded && onAdded({
            ...data,
            ...response.data.createService
        });

        enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
    }

    useEffect(()=>{
        if (!data?.findService) {
            return;
        }

        setValue("name", data.findService.name);
        setValue("enabled", data.findService.enabled);
        
        setValue("amount", data.findService.amount);
        setValue("amountWithInsurance", data.findService.amountWithInsurance);
        
        setValue("commissionMode", data.findService.commissionMode??'NONE');
        setValue("commissionAmount", data.findService.commissionAmount ?? 0);
        setValue("commissionPercent", data.findService.commissionPercent??0);

        setValue("commissionModeWithInsurance", data.findService.commissionModeWithInsurance??'NONE');
        setValue("commissionAmountWithInsurance", data.findService.commissionAmountWithInsurance ?? 0);
        setValue("commissionPercentWithInsurance", data.findService.commissionPercentWithInsurance??0);
        
        setValue("specialistId", data.findService.specialist?._id);
        setValue("requiredMedicalTests", data.findService.requiredMedicalTests || []);
        setValue("previousAppointmentRequired", data.findService.previousAppointmentRequired);
    }, [data]);

    const commissionMode = watch('commissionMode');
    const commissionModeWithInsurance = watch('commissionModeWithInsurance');

    return (
        <Paper>
            <FormProvider {...methods}>
                    <Box p={2}>
                        <Title sx={{marginBottom: 0}}>{t(isNew?"CreateService":"EditService")}</Title>
                    </Box>
                    <Divider />
                    <Box p={2}>
                        <Stack spacing={2}>
                            <FormSwitch 
                                label={t('Enabled')}
                                name="enabled"
                            />
                            <FormSwitch 
                                label={t('PreviousAppointmentRequired')}
                                name="previousAppointmentRequired"
                            />
                            <FormTextField 
                                label={t('Name')}
                                name="name"
                                variant="outlined"
                                fullWidth
                            />

                            <FormAutocompleteGetSpecialist
                                label={t('Specialist')}
                                name="specialistId"
                                loading={loading}
                                tenantId={tenantId}
                                fullWidth
                            />

                            <FormAutocompleteGetMedicalTests
                                label={t('RequiredMedicalTests')}
                                name="requiredMedicalTests"
                                loading={loading}
                                tenantId={tenantId}
                                fullWidth
                            />

                            <Stack direction="row" spacing={2}>
                                <FormTextField 
                                    label={t("AmountWithoutInsurance")}
                                    type='number'
                                    name="amount" 
                                    fullWidth
                                />
                                <FormTextField 
                                    label={t("AmountWithInsurance")}
                                    type='number'
                                    name="amountWithInsurance" 
                                    fullWidth
                                />
                            </Stack>
                    
                        </Stack>
                    </Box>
                    <Divider>{t("Commissions")}</Divider>
                    <Box p={2}>
                        <Stack>
                            <FormRadios 
                                options={commissionModes}   
                                label={t("CommissionMode")}
                                name="commissionMode" 
                                row
                            />

                            {commissionMode==='PERCENT' && 
                                (<FormSlider
                                    label={t('Percent')}
                                    name="commissionPercent"
                                    marks={commissionPercentMarks}
                                    fullWidth
                                />)
                            }
                            {commissionMode==='AMOUNT' && 
                                (<FormTextField 
                                    label={t("CommissionAmount")}
                                    type='number'
                                    name="commissionAmount" 
                                    fullWidth
                                />)
                            }
                        </Stack>
                    </Box>
                    <Divider>{t("CommissionsWithInsurance")}</Divider>
                    <Box p={2}>
                        <Stack>
                            <FormRadios 
                                options={commissionModes}   
                                label={t("CommissionMode")}
                                name="commissionModeWithInsurance" 
                                row
                            />

                            {commissionModeWithInsurance==='PERCENT' && 
                                (<FormSlider
                                    label={t('Percent')}
                                    name="commissionPercentWithInsurance"
                                    marks={commissionPercentMarks}
                                    fullWidth
                                />)
                            }
                            {commissionModeWithInsurance==='AMOUNT' && 
                                (<FormTextField 
                                    label={t("CommissionAmount")}
                                    type='number'
                                    name="commissionAmountWithInsurance" 
                                    fullWidth
                                />)
                            }
                        </Stack>
                    </Box>
                    <Divider />
                    <Box p={2}>
                        <Stack direction="row" justifyContent="space-between">
                            <Button variant='contained' color="grey" onClick={onCancel} disabled={isSubmitting}>{t('Cancel')}</Button>
                            <LoadingButton 
                                variant='contained' 
                                color='primary' 
                                loading={isSubmitting}
                                onClick={handleSubmit(onSubmit)}>{t('Submit')}</LoadingButton>
                        </Stack>
                    </Box>
            </FormProvider>
        </Paper>
    )
}