import {DataGrid, getGridDateOperators, getGridSingleSelectOperators, getGridStringOperators} from '@mui/x-data-grid'
import Chip from '@mui/material/Chip'
import { getAppointmentText, getPatientText, getServiceText, getInsuranceText, getSpecialistText } from '../shared/label-helpers';
import * as moment from 'moment';
import StatusChip from '../shared/StatusChip';
import { formatMoney} from '../shared/format-helpers';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { Tooltip, Typography } from '@mui/material';
import { Check, PriorityHigh, QuestionMark } from '@mui/icons-material';
import { green, grey, orange } from '@mui/material/colors';
import { GET_TAGS } from '../graphql/queries';
import { useTenantId } from '../tenant/hooks';
import { useQuery } from '@apollo/client';
import { useState } from 'react';

const STATUSES = [
  'PENDING',
  'QUEUED',
  'WORKING',
  'INCOMPLETE',
  'COMPLETE',
  'CANCELLED'
]

const buildColumns = (t, tags, filterable) =>[
    { 
      filterable: filterable,
      field: '_id', headerName: t('ID'), width: 70, 
    },
    { 
      filterable: filterable,
      field: 'code', 
      headerName: t('Code'), 
      width: 180,
      filterOperators: getGridStringOperators().filter(x=>x.value === 'contains')
    },
    { 
      filterable: filterable,
      sortable: false,
      field: 'confirmed', 
      headerName: t('Confirmed'), 
      width: 100,
      align: 'center',
      type: 'singleSelect',
      filterOperators: getGridSingleSelectOperators().filter(x=>x.value === 'is'),
      valueOptions:(params)=>[
        { value: true, label: t('Yes') },
        { value: false, label: t('No') }
      ],
      valueFormatter: ({value}) => { return (value===true? t('Yes') : (value===false? t('No'): t('NoAnswerYet')))},
      renderCell: ({value})=><Tooltip title={value===true ? t('Confirmed') : (value===false?  t('Unconfirmed') : t('NoAnswerYet'))}>
        <Typography color={value===true ? green[800] : (value===false ? orange[800] : grey[800])}>
          {value===true ? <Check ></Check>:
            (value===false ? 
              <PriorityHigh></PriorityHigh>:
              <QuestionMark></QuestionMark>
            )
          }
        </Typography>
      </Tooltip>,
    },
    { 
      filterable: false,
      sortable: false,
      field: 'confirmationMode', 
      headerName: t('ConfirmationMode'), 
      width: 100,
      align: 'center',
      renderCell: ({value})=>value ?? t(value),
    },
    { 

      filterable: filterable,
      field: 'status', headerName: t('Status'), width: 150, 
      type: 'singleSelect',
      valueOptions:(params)=>STATUSES,
      filterOperators: getGridSingleSelectOperators().filter(x=>x.value === 'is'),
      renderCell: ({value}) => <StatusChip status={value} />
    },
    { 
      filterable: false,
      sortable: false,
      field: 'patient', 
      headerName: t('PatientName'), 
      width: 130,
      valueGetter: ({value})=> getPatientText(value)
    },
    { 
      filterable: false,
      sortable: false,
      field: 'patientPhoneNumber', 
      headerName: t('TutorPhoneNumber'), 
      width: 130,
      valueGetter: ({value, row})=> row.patient?.tutorPhoneNumber
    },
    { 
      filterable: filterable,
      type: 'date',
      field: 'date', 
      headerName: t('Date'), 
      width: 130 ,
      filterOperators: getGridDateOperators().filter(x=>x.value === 'is'),
      valueGetter: ({value}) =>  moment(value).toDate(),
      renderCell: ({value}) =>  moment(value).format('DD-MM-yyyy')
    },
    { 
      filterable: false,
      field: 'time', headerName: t('Time'), width: 90 
    },
    { 
      filterable: false,
      field: 'specialist', 
      sortable: false,
      headerName: t('Specialist'), 
      width: 230,
      valueGetter: ({value}) => getSpecialistText(value)
    },
    { 
      filterable: false,
      field: 'service', 
      sortable: false,
      headerName: t('Service'), 
      width: 130,
      valueGetter: ({value}) => getServiceText(value)
    },
    { 
      filterable: filterable,
      sortable: false,
      field: 'tags', 
      type: 'singleSelect',
      headerName: t('Tags'), 
      valueOptions:(params)=>(tags??[]),
      filterOperators: getGridSingleSelectOperators().filter(x=>x.value === 'is'),
      getOptionValue: (value) => value?._id??'',
      getOptionLabel: (value) => value?.name??'',
      minWidth: 130,
      //filterOperators: getGridDateOperators().filter(x=>x.value === 'is'),
      renderCell: ({value, row}) => row.tags?.map((g,index)=><Chip size="small" color='default' key={index} label={g.name} />),
      valueGetter: ({value}) => (value?.map((g) => g.name)??[]).join(','),
    },
    { 
      filterable: false,
      sortable: false,
      field: 'previousAppointment', 
      headerName: t('PreviousAppointment'), 
      width: 130,
      valueGetter: ({value}) => getAppointmentText(value)
    },
    { 
      filterable: false,
      sortable: false,
      field: 'requiredMedicalTests', 
      headerName: t('RequiredMedicalTests'), 
      minWidth: 250,
      renderCell: ({value, row}) => row.requiredMedicalTests?.map((g,index)=><Chip size="small" color={g.metadata?.color} key={index} label={g.name} />),
      valueGetter: ({value}) => (value?.map((g) => g.name)??[]).join(','),
    },
    { 
      filterable: false,
      sortable: false,
      field: 'doneMedicalTests', 
      headerName: t('DoneMedicalTests'), 
      minWidth: 250,
      renderCell: ({value, row})=>row.doneMedicalTests?.map((g,index)=><Chip size="small" color={g.metadata?.color} key={index} label={g.name} />),
      valueGetter: ({value}) => (value?.map((g) => g.name)??[]).join(','),
    },
    { 
      filterable: false,
      sortable: false,
      field: 'requestedMedicalTests', 
      headerName: t('RequestedMedicalTests'), 
      minWidth: 250,
      renderCell: ({value, row})=>row.requestedMedicalTests?.map((g,index)=><Chip size="small" color={g.metadata?.color} key={index} label={g.name} />),
      valueGetter: ({value}) => (value?.map((g) => g.name)??[]).join(','),
    },
    { 
      filterable: false,
      sortable: false,
      field: 'insurance', 
      headerName: t('Insurance'), 
      minWidth: 130,
      valueGetter: ({value}) => getInsuranceText(value)
    },
    { 
      filterable: false,
      sortable: false,
      type: 'number',
      field: 'totalAmount', 
      headerName: t('TotalAmount'), 
      align: 'right',
      minWidth: 130,
      valueGetter: ({value}) => formatMoney(value)
    }
  ];

const AppointmentList = ({
    rows, 
    count, 
    loading, 
    onFilterModelChange, 
    onSortModelChange, 
    onPaginationModelChange, 
    onRowSelectedChanged, 
    toolbar, 
    apiRef,
    filterable
}) => {
  const [tenantId] = useTenantId();
  const { data: tagData } = useQuery(GET_TAGS, {
      variables: {
        tenants: [tenantId],
        categories: ['APPOINTMENT']
    }
  });

  const {t} = useTranslation();
  const columns = buildColumns(t, (tagData?.getTags??[]), filterable);

  const [filterModel, setFilterModel] = useState({
    items:[]
  });

  return (
    <Box sx={{ height: 450, width: '100%' }}>
      <DataGrid
        apiRef={apiRef}
        density='compact'
        rows={rows}
        columns={columns}
        rowCount={count}
        loading={loading}
        filterModel={filterModel}
        getRowId={(r)=>r._id}
        onRowSelectionModelChange={onRowSelectedChanged}
        initialState={{
          columns: {
            columnVisibilityModel: {
              _id: false,
              requiredMedicalTests: false,
              previousAppointment: false,
              doneMedicalTests: false,
              requestedMedicalTests: false
            }
          },
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        onRowClick={({row}) => console.log(row)}
        pageSizeOptions={[10, 15, 20]}
        onPaginationModelChange={onPaginationModelChange}
        checkboxSelection
        onSortModelChange={onSortModelChange}
        paginationMode="server"
        onFilterModelChange={(e)=>{
          setFilterModel(e);
          onFilterModelChange && onFilterModelChange(e);
        }}
        filterMode="server"
        slots={{
          toolbar: toolbar
        }}
      />
    </Box>
  );
}

export default AppointmentList;