import React, { useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, pdf } from '@react-pdf/renderer';
import { AppBar, Button, Dialog, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { FIND_APPOINTMENT_BY_ID } from '../graphql/queries';
import { formatMoney } from '../shared/format-helpers';
import * as moment from 'moment';
import { useTenant } from '../tenant/hooks';

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 10,
    backgroundColor: '#E4E4E4'
  },
  section: {
    row: {
      margin: 0,
      padding: 0,
      display: 'flex',
      flexDirection: "row",
      flexGrow: 1,
    },
    left: {
      margin: 0,
      padding: 0,
      flex: 1,
      fontWeight: 'bold'
    },
    right: {
      margin: 0,
      padding: 0,
      flex: 1,
      textAlign: 'right',
      fontWeight: 'normal'
    },
    title: {
      margin: 0,
      padding: 10,
      flex: 1,
      textAlign: 'center'
    }
  }
});

// Create Document Component
const PDFVoucher = ({
  code,
  specialist,
  service,
  date,
  time,
  totalAmount,
  companyName
}) => {
  const {t} = useTranslation();
  return (<Document>
    <Page size="B7" orientation="landscape" style={styles.page}>
      <View style={{flexDirection: 'column'}}>
        <View style={styles.section.row}>
          <Text style={styles.section.title}>{companyName}</Text>
        </View>
        <View style={styles.section.row}>
          <Text style={styles.section.left}>{t('Code')}: </Text>
          <Text style={styles.section.right}>{code}</Text>
        </View>

        <View style={styles.section.row}>
          <Text style={styles.section.left}>{t('Date')}: </Text>
          <Text style={styles.section.right}>{date}</Text>
        </View>

        <View style={styles.section.row}>
          <Text style={styles.section.left}>{t('Time')}: </Text>
          <Text style={styles.section.right}>{time}</Text>
        </View>

        <View style={styles.section.row}>
          <Text style={styles.section.left}>{t('Specialist')}: </Text>
          <Text style={styles.section.right}>{specialist}</Text>
        </View>

        <View style={styles.section.row}>
          <Text style={styles.section.left}>{t('Service')}: </Text>
          <Text style={styles.section.right}>{service}</Text>
        </View>

        <View style={styles.section.row}>
          <Text style={styles.section.left}>{t('Amount')}: </Text>
          <Text style={styles.section.right}>{totalAmount}</Text>
        </View>
      </View>
    </Page>
  </Document>);
};

export default ({open, appointmentId, onClose}) => {
  const [tenant] = useTenant();
  const {data, loading, refetch} = useQuery(FIND_APPOINTMENT_BY_ID, {
    variables: {
        appointmentId: appointmentId
    },
    skip: !appointmentId || !open
  });

  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const phone = data?.findAppointment?.patient?.tutorPhoneNumber;
  const code = data?.findAppointment?.code;
  const specialist = data?.findAppointment?.specialist?.name;
  const service = data?.findAppointment?.service?.name;
  const date = moment(data?.findAppointment?.date).format('LL');
  const time = data?.findAppointment?.time;
  const totalAmount = formatMoney(data?.findAppointment?.totalAmount);

  const props = { 
    code,
    specialist,
    service,
    date,
    time,
    totalAmount,
    companyName: tenant?.name
  };

  const handleShareInWhatsApp = async () => {
    const breakline = '%0a';

    const rows = [
      [t('Code'), code],
      [t('Specialist'), specialist],
      [t('Service'), service],
      [t('Date'), date],
      [t('Time'), time],
      [t('TotalAmount'), totalAmount],
    ];

    const text = rows.reduce((t, r) => t+`*${r[0]}:* ${r[1]??''}${breakline}` , '');

    if (phone) {
      window.open(`whatsapp://send?phone=${phone}&text=${encodeURI(text)}`);
    } else{
      window.open(`whatsapp://send?text=${encodeURI(text)}`);
    }
    window.close();
  }
  
  const handleCopyToClipboard = async () => {
    const blobPdf = await pdf(PDFVoucher(props)).toBlob();
    await navigator.clipboard.write([
      new ClipboardItem({
        [blobPdf.type]: blobPdf,
      }),
    ]);

    enqueueSnackbar(t("voucher-copied-in-the-clipboard"), {variant: "success"});
  }

  return <Dialog  
          open={open && data && !loading} 
          onClose={onClose}
          fullScreen
          >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {t('Voucher')}
          </Typography>
          <Button color="inherit">
            <Stack direction="row" spacing={1} onClick={handleShareInWhatsApp}>
              <ShareIcon />
              <Typography>
                {t('ShareInwhatsapp')}
              </Typography>
            </Stack>
          </Button>
          <Button color="inherit">
            <Stack direction="row" spacing={1} onClick={handleCopyToClipboard}>
              <ContentCopyIcon />
              <Typography>
                {t('CopyInClipboard')}
              </Typography>
            </Stack>
          </Button>
        </Toolbar>
      </AppBar>
      <PDFViewer height='100%'>
        <PDFVoucher 
           {...props}
        />
      </PDFViewer>
  </Dialog>
}